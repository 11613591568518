import React from 'react';

const QuickLinksPlaceholder = () => {
  return (
    <div className="placeholder-ql">
      <div className="placeholder-ql__header">
        <div className="placeholder-row placeholder-ql__header-row animation" />
        <div className="placeholder-row placeholder-ql__header-row animation" />
        <div className="placeholder-row placeholder-ql__header-row animation" />
      </div>
      <div className="placeholder-ql__card-list">
        <div className="placeholder-ql__card animation" />
        <div className="placeholder-ql__card animation" />
      </div>
    </div>
  );
};

export default QuickLinksPlaceholder;
