import React from 'react';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { keywordsQuery } from '../../queries';
import SectionHeader from '../SectionHeader';
import './styles.scss';

class PopularRequests extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    i18n: PropTypes.object,
    client: PropTypes.object
  };

  render() {
    const {
      t,
      i18n: { language }
    } = this.props;

    if (language === 'ru') return null;

    return (
      <div className="popular-requests-section main-col">
        <SectionHeader title={t('sections.popular_requests.title')} />
        <ul className="popular-requests">
          <Query query={keywordsQuery} fetchPolicy="cache-first">
            {({ loading, data, error }) => {
              if (error || loading) return null;

              const keywordsMarkup =
                data.keywords &&
                data.keywords.map(word => (
                  <li key={word.id}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={word.url}
                    >
                      {word.term}
                    </a>
                  </li>
                ));

              return keywordsMarkup;
            }}
          </Query>
        </ul>
      </div>
    );
  }
}

export default withTranslation()(PopularRequests);
