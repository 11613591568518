import React from 'react';
import HomePage from '../components/Views/HomePage';
import { getSeoData } from '../utils/helpers';

const App = () => <HomePage />;

App.getInitialProps = async ({ apolloClient, asPath, req }) => {
  const seoData = await getSeoData(apolloClient, asPath, req);

  return {
    seoData
  };
};

export default App;
